import React from "react"
import { graphql } from "gatsby"
import { navigate } from '@reach/router';
import queryString from 'query-string';

import clone from 'clone-deep';


import { Container, Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import Layout from '../components/Layout';


import Stepper from '../components/Stepper';

import ModelConfigHeader from '../components/ModelConfig/ModelConfigHeader';
import ModelConfigTabs from '../components/ModelConfig/ModelConfigTabs';
import TrimList from '../components/ModelConfig/TrimList';
import ConfigExteriorColors from '../components/ModelConfig/ConfigExteriorColors';
import ConfigInteriorColors from '../components/ModelConfig/ConfigInteriorColors';
import BottomBar from "../components/ModelConfig/BottomBar";

import {
  prepandDefaultExteriorColor,
  prepandDefaultInteriorColor,
  CarMake,
  CarModel,
  ModelTrim,
} from 'leasemojo-common';

import { useQueryParams, NumberParam } from "use-query-params";
import { trackEvent } from "../analytics";

interface Props {
  data: {
    car: CarMake;
    model: CarModel;
    allTrim: {
      nodes: ModelTrim[]
    }
  }
}



const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {

      },
      layout: {
        paddingBottom: theme.spacing(2),
      }
    }
  ),
);

const ModelPage: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  const [ params, setParams ] = useQueryParams({
    step: NumberParam,
    trim: NumberParam,
    exterior: NumberParam,
    interior: NumberParam
  });

  const { step = 0, trim = 0, exterior = 0, interior = 0 } = params;

  const trimData = clone(data.allTrim.nodes[ trim ]);

  trimData.exteriorColors = prepandDefaultExteriorColor(trimData.exteriorColors, trimData.defaultColors.exteriorColorId);
  trimData.interiorColors = prepandDefaultInteriorColor(trimData.interiorColors, trimData.defaultColors.interiorColorId);

  const steps = [ 'trim', 'exterior', 'interior' ];

  const setStep = (value: number) => {

    if (typeof steps[ value ] !== 'undefined') {
      trackEvent('pickCarConfig', { step: steps[ value ] })
    }

    setParams({
      ...params,
      step: value
    }, 'replace');
  }

  const setTrim = (value: number) => {
    if (trim === value) {
      return;
    }

    setParams({
      ...params,
      trim: value,
      exterior: 0,
      interior: 0,
    }, 'replace');
  }

  const setExteriorColor = (value: number) => {
    setParams({
      ...params,
      exterior: value
    }, 'replace');
  }
  const setInteriorColor = (value: number) => {
    setParams({
      ...params,
      interior: value
    }, 'replace')
  }

  const onTabChange = (event: any, index: number) => {
    setStep(index);
  }

  const goNext = () => {
    if (params.step === 2) {
      const queryStr = queryString.stringify({
        exterior: params.exterior,
        interior: params.interior,
      });
      const url = `/build/${data.car.slug}/${data.model.slug}/${trimData.slug}?${queryStr}`;
      trackEvent('pickCarSummary')
      navigate(url);
      return;
    }

    setStep(step + 1);
  }

  const goBack = () => {
    if (step === 0) {
      return;
    }

    setStep(step - 1);
  }


  const getTabContent = () => {
    if (step === 0) {
      return <TrimList trims={data.allTrim.nodes} selected={trim} onChange={setTrim} model={data.model} />;
    }
    else if (step === 1) {
      return <ConfigExteriorColors colors={trimData.exteriorColors} selected={exterior} onChange={setExteriorColor} defaultIndex={0} />;
    }
    else if (step === 2) {
      return <ConfigInteriorColors colors={trimData.interiorColors} selected={interior} onChange={setInteriorColor} defaultIndex={0} />;
    }
  }

  const title = `${data.car.name} ${data.model.name}`;
  const ogImage = `${process.env.CDN_BASE_URL_CARS}/${data.model.imageUrl}`;

  return (
    <Layout contentClassName={classes.layout} title={title} ogImage={ogImage}>
      <Container maxWidth="md">
        <Stepper activeStep={2} completedSteps={1} />

        <ModelConfigHeader
          car={data.car}
          model={data.model}
          trim={trimData}
          exteriorColorIndex={exterior}
          interiorColorIndex={interior}
        />

        <ModelConfigTabs index={step} onChange={onTabChange} />
        {
          getTabContent()
        }
      </Container>
      <BottomBar canGoBack={step > 0} isLastStep={step === 2} next={goNext} back={goBack} />
    </Layout>
  )
}

export default ModelPage;

export const query = graphql`
  query($carId: String!, $modelId: String!) {
    car(id: { eq: $carId }) {
      name
      logoUrl
      slug
    }
    model(id: { eq: $modelId }) {
      id
      imageUrl
      placeholderImage
      name
      slug
    }
    allTrim(
      filter: { modelId: { eq: $modelId } }
      sort: { fields: msrp, order: ASC }
    ) {
      nodes {
        id
        originalId
        name
        msrp
        slug,
        defaultColors {
          exteriorColorId
          interiorColorId
        }
        exteriorColors {
          images {
            md
            sm
            xsm
          }
          color
          msrp
          name
          rgbHex,
          id,
        }
        interiorColors {
          id
          msrp
          name
        }
      }
    }
  }
`
