import React from "react";
import { Step as MuiStep, Stepper as MuiStepper, StepButton as MuiStepButton } from '@material-ui/core';

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";


const steps = [
  'Car',
  'Model',
  'Options',
  'Offers',
];


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      stepper: {
        backgroundColor: 'transparent',
        padding: theme.spacing(2, 0),
      }
    }
  ),
);

interface StepperProps {
  activeStep: number;
  completedSteps: number;
}

const Stepper: React.FC<StepperProps> = ({activeStep, completedSteps}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MuiStepper alternativeLabel nonLinear activeStep={activeStep} className={classes.stepper}>
        {steps.map((step, index) => {
          return (
            <MuiStep key={step} completed={index <= completedSteps}>
              <MuiStepButton disabled>
                {step}
              </MuiStepButton>
            </MuiStep>
          );
        })}
      </MuiStepper>
    </div>
  );
};

export default Stepper;