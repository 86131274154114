import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import { ExteriorColor } from "leasemojo-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      itemColor: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        marginRight: theme.spacing(2),
      },
    }
  ),
);

interface Props {
  colors: ExteriorColor[],
  selected?: number;
  defaultIndex: number;
  onChange: (index: number) => void;
}

const ConfigExteriorColors: React.FC<Props> = ({ colors, selected, onChange, defaultIndex }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0}>
      <List>
        {
          colors.map((color, index) => {
            return (
              <ListItem selected={selected === index} key={color.id} button onClick={() => {
                onChange(index);
              }}>
                <ListItemAvatar>
                  <div className={classes.itemColor} style={{
                    backgroundColor: '#' + color.rgbHex,
                  }} />
                </ListItemAvatar>
                <ListItemText primary={color.name} secondary={color.id === 0 ? '' : '$' + color.msrp.toString()} />
                <ListItemSecondaryAction style={{
                  opacity: selected === index ? 1 : 0
                }}>
                  <CheckIcon color="secondary" />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        }
      </List>
    </Paper>
  );
};

export default ConfigExteriorColors;