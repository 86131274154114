import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Button } from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        // height: 84,
        backgroundColor: '#fff',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        paddingBottom: `calc(24px + env(safe-area-inset-bottom, 0px))`,
        paddingTop: theme.spacing(3),
      },
      spacer: {
        width: 20,
      }
    }
  ),
);

interface Props {
  isLastStep: boolean;
  canGoBack: boolean;
  next: () => void;
  back: () => void;
}

const BottomBar: React.FC<Props> = ({ canGoBack, isLastStep, next, back }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button variant="contained" color="secondary" disabled={!canGoBack} onClick={back}>Back</Button>
      <div className={classes.spacer}></div>
      <Button variant="contained" color="secondary" onClick={next} >{
        isLastStep ? 'Finish' : 'Next'
      }</Button>
    </div>
  );
};

export default BottomBar;