import React from "react";

import { Paper } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CheckIcon from '@material-ui/icons/Check';
import { InteriorColor } from "leasemojo-common";


interface Props {
  colors: InteriorColor[],
  selected?: number;
  onChange: (index: number) => void;
  defaultIndex: number;
}

const ConfigInteriorColors: React.FC<Props> = ({ colors, selected, onChange, defaultIndex }) => {


  return (
    <Paper elevation={0}>
      <List>
        {
          colors.map((color, index) => {
            return (
              <ListItem selected={selected === index} key={color.id} button onClick={() => {
                onChange(index);
              }}>
                <ListItemText primary={color.name} secondary={color.id === 0 ? '' : '$' + color.msrp.toString()} />
                <ListItemSecondaryAction style={{
                  opacity: selected === index ? 1 : 0
                }}>
                  <CheckIcon color="secondary" />
                </ListItemSecondaryAction>

              </ListItem>
            );
          })
        }
      </List>
    </Paper>
  );
};

export default ConfigInteriorColors;