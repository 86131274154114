import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography, Hidden } from "@material-ui/core";
import { CarMake, CarModel, ModelTrim } from "leasemojo-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(1, 2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
        zIndex: 1,
        position: 'sticky',
        boxShadow: theme.shadows[1],
        borderRadius: 3,
        top: 56,
        [ theme.breakpoints.up('sm') ]: {
          top: 64,
        },
      },
      leftSide: {
        display: 'flex',
        flexDirection: 'row',
      },
      rightSide: {

      },
      image: {
        width: 110,
        height: 55,
        marginRight: theme.spacing(3),
        [ theme.breakpoints.up('sm') ]: {
          width: 130,
          height: 65,
        },
      },
    }
  ),
);


interface Props {
  car: CarMake,
  model: CarModel,
  trim: ModelTrim,
  exteriorColorIndex: number,
  interiorColorIndex: number,
}

const ModelConfigHeader: React.FC<Props> = ({ car, model, trim, exteriorColorIndex, interiorColorIndex }) => {
  const classes = useStyles();
  const imgUrl = trim.exteriorColors[ exteriorColorIndex ].images.md ? trim.exteriorColors[ exteriorColorIndex ].images.md : model.placeholderImage;
  
  const alt = `${car.name} - ${model.modelYear} ${model.name} - ${trim.name}`;
  let priceNum = trim.msrp + trim.exteriorColors[ exteriorColorIndex ].msrp + trim.interiorColors[ interiorColorIndex ].msrp;
  let price = priceNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return (
      <div className={classes.root}>
        <div className={classes.leftSide}>
          <img className={classes.image} src={process.env.CDN_BASE_URL_CARS + "/" + imgUrl} alt={alt} />
          <div>
            <Typography variant="h6">{car.name} - {model.name}</Typography>
            <Typography variant="body2">{trim.name}</Typography>
            <Hidden smUp implementation="css">
              <Typography variant="body2">Starting at <strong>${price}</strong></Typography>
            </Hidden>
          </div>
        </div>
        <Hidden xsDown implementation="css">
          <div className={classes.rightSide}>
            <Typography variant="h6">Starting at ${price}</Typography>
          </div>
        </Hidden>
      </div>
  );
};

export default ModelConfigHeader;