import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, ListItemSecondaryAction } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';


import { ModelTrim, CarModel, getExteriorColorIndex } from "leasemojo-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      list: {
        width: '100%',
        maxWidth: 360,
      },
      avatar: {
        width: 60,
        height: 60,
        marginRight: theme.spacing(2),
      },
      image: {
        height: 25,
      }
    }
  ),
);

interface Props {
  model: CarModel;
  trims: ModelTrim[];
  selected?: number;
  onChange: (index: number) => void;
}

const TrimListConfig: React.FC<Props> = ({ model, trims, selected, onChange }) => {
  const classes = useStyles();


  return (
    <div className={classes.root}>

      <Paper elevation={0}>
        <List dense>
          {
            trims.map((trim, index) => {
              const colorIndex = getExteriorColorIndex(trim.defaultColors.exteriorColorId, trim.exteriorColors);
              const imgUrl = trim.exteriorColors[ colorIndex ].images.md ? trim.exteriorColors[ colorIndex ].images.md : model.placeholderImage;
              return (
                <ListItem button key={trim.id} onClick={() => {
                  onChange(index);
                }}>
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <img className={classes.image} src={process.env.CDN_BASE_URL_CARS + "/" + imgUrl} alt={trim.name} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={trim.name} secondary={'$' + trim.msrp.toString()} />
                  <ListItemSecondaryAction style={{
                    opacity: selected === index ? 1 : 0
                  }}>
                    <CheckIcon color="secondary" />
                  </ListItemSecondaryAction>

                </ListItem>
              );
            })
          }
        </List>
      </Paper>

    </div>
  );
};

export default TrimListConfig;