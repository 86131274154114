import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        marginBottom: theme.spacing(4),
      },
      container: {
        overflow: 'hidden',
      },
    }
  ),
);

interface Props {
  index: number,
  onChange: (event: any, index: number) => void,
}

const ModelConfigTabs: React.FC<Props> = ({index, onChange}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.container}>
        <Tabs
          value={index}
          onChange={onChange}
          indicatorColor="secondary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Trim" />
          <Tab label="Exterior" />
          <Tab label="Interior" />
        </Tabs>
      </Paper>
    </div>
  );
};

export default ModelConfigTabs;